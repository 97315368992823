import * as React from "react"

// import {
//     Link
// } from 'gatsby';

import { Link } from "gatsby-theme-material-ui";

import { StaticImage } from 'gatsby-plugin-image';

import {Button, Box} from '@mui/material';

import '../styles/app-banks.scss';

const App = () => {
  return (
    <div className="wrapper-app">
        <section className="section-app">
            <h2 className="title-app">Un banco de posibilidades en menos de 3 minutos</h2>

            <p className="description-app">Trabajamos con los mejores bancos de México.</p>

            <Box sx={{display: {md: 'block', xs: "none"}}}>
              <StaticImage src="../images/app-banks-3.png" className="bg-app" alt="Mi mejor hipoteca" />
            </Box>
            <Box sx={{display: {md: 'none', xs: "block"}, py: 4}}>
              <StaticImage src="../images/app-banks-mobile.png" className="bg-app" alt="Mi mejor hipoteca" />
            </Box>

            <Button component={Link} to="/precalificar" className="btn-app" size="large" variant="contained">Calcula tu crédito ahora</Button>
        </section>
    </div>
  )
}

export default App

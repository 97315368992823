import * as React from "react"

// layout
import Layout from "../layout"
import HeroPymes from "../components/hero-pymes"
import ServicesPymes from "../components/services-pymes"
import InfoPymes from "../components/info-pymes"
import Steps from "../components/steps"
import App from "../components/app"
import Carousel from "../components/carousel"

const PymesPage = () => {
  return (
    <Layout>
        <HeroPymes />
        <Carousel />
        <InfoPymes />
        <Steps />
        <ServicesPymes />
        <App />
    </Layout>
  )
}

export default PymesPage

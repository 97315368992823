import * as React from "react"

import { StaticImage } from 'gatsby-plugin-image';

import {Box} from "@mui/material"

import '../styles/steps.scss'

const Steps = () => {

    return (
        <Box sx={{padding: {xs: "40px 20px", md: '100px 20px'}}} className="wrapper-steps"> 
            <section className="steps">
                <div className="title">
                    <h3 className="title-steps">Precalifícate en línea</h3>
                    <p className="description-steps">¡Rápido y simple!</p>
                </div>

                <div className="wrapper-steps-1"> 
                    <StaticImage src="../images/steps/one.png" className="img-step" alt='Precalifica' />
                    <h4>Precalifica para tu crédito</h4>
                </div>

                <div className="wrapper-steps-2"> 
                    <StaticImage src="../images/steps/two.png" className="img-step" alt='Asesoria' />
                    <h4>Recibe asesoria de expertos</h4>
                </div>

                <div className="wrapper-steps-3"> 
                    <StaticImage src="../images/steps/three.png" className="img-step" alt='Autorización' />
                    <h4>El banco Autoriza tu crédito</h4>
                </div>

                <div className="wrapper-steps-4"> 
                    <StaticImage src="../images/steps/four.png" className="img-step" alt='Disfruta' />
                    <h4>Disfruta de tu crédito</h4>
                </div>

            </section>
        </Box>
    )
}

export default Steps

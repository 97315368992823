import * as React from "react";


import { StaticImage } from 'gatsby-plugin-image';

import { Box } from "@mui/material";

const Carousel = () => {
  return (
    <Box
      sx={{
        position: 'sticky',
        top: 61,
        zIndex: 10,
        width: '100%',
        maxWidth: '100%',
        background: "#fff",
        overflow: "hidden",
        borderBottom: "1px solid #d2d2d2"
      }}
    >
      <Box
        sx={{
          // maxWidth: "1440px",
          // width: "1360px",
          width: "100%",
          height: "70px",
          position: "relative",
          margin: "0 auto",
          overflow: "hidden",
          py: "10px"
        }}
      >
        <Box
          sx={{
            width: {xs: "50px", md: "200px"},
            height: "100%",
            background: "linear-gradient(60deg, #fff, transparent)",
            position: "absolute",
            top: 0,
            zIndex: 2
          }}
        ></Box>

        <Box
          sx={{
            display: "flex",
            animation: "scroll 40s linear infinite",
            width: "calc(250px * 16)",
            position: "absolute",
            height: "50px",
            "& > div": {
              width: "250px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              justifyContent: "center",
              "& img": {
                height: "30px",
                objectFit: "contain"
              }
            }
          }}
        >
          <Box>
            <StaticImage src="../images/banks/banregio.png" width={250} height={50} alt="Mi mejor hipoteca" />
          </Box>
          <Box>
            <StaticImage src="../images/banks/afirme.png" width={250} height={50} alt="Mi mejor hipoteca" />
          </Box>
          <Box>
            <StaticImage src="../images/banks/banca-mifel.png" width={250} height={50} alt="Mi mejor hipoteca" />
          </Box>
          <Box>
            <StaticImage src="../images/banks/banorte.png" width={250} height={50} alt="Mi mejor hipoteca" />
          </Box>
          <Box>
            <StaticImage src="../images/banks/citibanamex.png" width={250} height={50} alt="Mi mejor hipoteca" />
          </Box>
          <Box>
            <StaticImage src="../images/banks/hsbc.png" width={250} height={50} alt="Mi mejor hipoteca" />
          </Box>
          <Box>
            <StaticImage src="../images/banks/santander.png" width={250} height={50} alt="Mi mejor hipoteca" />
          </Box>
          <Box>
            <StaticImage src="../images/banks/scotiabank.png" width={250} height={50} alt="Mi mejor hipoteca" />
          </Box>
          
          <Box>
            <StaticImage src="../images/banks/banregio.png" width={250} height={50} alt="Mi mejor hipoteca" />
          </Box>
          <Box>
            <StaticImage src="../images/banks/afirme.png" width={250} height={50} alt="Mi mejor hipoteca" />
          </Box>
          <Box>
            <StaticImage src="../images/banks/banca-mifel.png" width={250} height={50} alt="Mi mejor hipoteca" />
          </Box>
          <Box>
            <StaticImage src="../images/banks/banorte.png" width={250} height={50} alt="Mi mejor hipoteca" />
          </Box>
          <Box>
            <StaticImage src="../images/banks/citibanamex.png" width={250} height={50} alt="Mi mejor hipoteca" />
          </Box>
          <Box>
            <StaticImage src="../images/banks/hsbc.png" width={250} height={50} alt="Mi mejor hipoteca" />
          </Box>
          <Box>
            <StaticImage src="../images/banks/santander.png" width={250} height={50} alt="Mi mejor hipoteca" />
          </Box>
          <Box>
            <StaticImage src="../images/banks/scotiabank.png" width={250} height={50} alt="Mi mejor hipoteca" />
          </Box>

        </Box>

        <Box
          sx={{
            width: {xs: "50px", md: "200px"},
            height: "100%",
            background: "linear-gradient(-60deg, #fff, transparent)",
            position: "absolute",
            top: 0,
            right: 0,
            zIndex: 2
          }}
        ></Box>
      </Box>
    </Box>
  )
}

export default Carousel

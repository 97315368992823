import * as React from "react"

// import {
//     Link
// } from 'gatsby';

import { Link } from "gatsby-theme-material-ui";

import { StaticImage } from 'gatsby-plugin-image';

import '../styles/hero.scss'

import { Typography, Button, Box } from "@mui/material";

const HeroPymes = () => {
  return (
    <section className="hero">
      
        <StaticImage src="../images/hero/hero-pymes.png" className="bg-hero" alt="Mi mejor hipoteca" />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            alignItems: {xs: "center", md: "start"},
            gridArea: "1/1",
            maxWidth: "1440px",
            width: "100%",
            zIndex: 2,
            alignSelf: "center",
            mx: "auto",
            px: {xs: "0", md: "20px"}
          }}
        >
          <Typography 
            component="h1" 
            className="title" 
            variant="display1"
            sx={{
              fontSize: {
                xs: "36px",
                sm: "48px",
                md: "68px"
              },
              textAlign: {xs: "center", md: "left"}
            }}
          >Servicios créditicios <br /> a la altura de tu negocio</Typography>
          <Typography 
            variant="body1" 
            className="main-text"
            sx={{
              fontSize: {
                xs: "15px",
                sm: "18px",
                md: "22px"
              },
              textAlign: {xs: "center", md: "left"}
            }}
          >Obtén el mejor <b>crédito hipotecario</b> con asesoría de <br /> expertos certificados.</Typography>
          <Button component={Link} to="/precalificar" role="btn-hero" size="large" variant="contained">Calcula tu crédito</Button>
        </Box>
    </section>
  )
}

export default HeroPymes
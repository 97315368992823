import * as React from "react"

import { StaticImage } from 'gatsby-plugin-image';

import '../styles/services.scss'

const Services = () => {

    return (
        <div className="wrapper-services"> 
            <section className="services-pymes">
                <h3 className="title-services">Descubre porqué somos la mejor opción en Pyme</h3>

                <StaticImage src="../images/services/pymes/1.png" className="img-service-1" alt="Arrendamiento Financiero" />
                <div className="wrapper-service-1"> 
                    <h4>Arrendamiento Financiero</h4>
                    <p className="wrapper-text">Es un instrumento de financiamiento a mediano y largo plazo para adquirir toda clase de unidades de transporte nuevas o usadas y equipamiento en general, así como la adquisición de equipo médico, a excepción de equipos de cómputo.</p>
                </div>

                <StaticImage src="../images/services/pymes/2.png" className="img-service-2" alt="Factoraje a Clientes" />
                <div className="wrapper-service-2"> 
                    <h4>Factoraje a Clientes</h4>
                    <p className="wrapper-text">Es un producto financiero que convierte en efectivo las cuentas por cobrar documentadas como derechos de crédito, comúnmente denominadas facturas, pagarés, letras de cambio, a un precio determinado o determinable.</p>
                </div>

                <StaticImage src="../images/services/pymes/3.png" className="img-service-3" alt="Crédito Simple" />
                <div className="wrapper-service-3"> 
                    <h4>Crédito Simple</h4>
                    <p className="wrapper-text">Es un instrumento crediticio orientado a financiar capital de trabajo como compra de inventarios, pagos de nómina, compra de activos y adquisición de locales, oficinas, consultorios médicos, bodegas entre otros.</p>
                </div>

                <StaticImage src="../images/services/pymes/4.png" className="img-service-4" alt=" Factoraje a proveedores" />
                <div className="wrapper-service-4"> 
                    <h4> Factoraje a proveedores</h4>
                    <p className="wrapper-text">Para todas las personas morales que tengan, buen historial en Buró de Crédito, Solidez financiera probada y una antigüedad de operación mínima de 2 años comprobables con facturas y las empresas que requieran el servicio de factoraje para clientes podrán solicitar un monto de 1 a 5 millones de pesos.</p>
                </div>

                <StaticImage src="../images/services/pymes/5.png" className="img-service-5" alt="Consultorios Médicos" />
                <div className="wrapper-service-5"> 
                    <h4>Consultorios Médicos</h4>
                    <p className="wrapper-text">Destinado para aquellos profesionistas dedicados a las áreas relacionadas con la salud, aplicable en la adquisición de consultorios ubicados en torres médicas independientemente de la especialidad a la que se dedique el cliente.</p>
                </div>

                <StaticImage src="../images/services/pymes/6.png" className="img-service-6" alt="Naves industriales" />
                <div className="wrapper-service-6"> 
                    <h4>Naves industriales</h4>
                    <p className="wrapper-text">Oferta de producto de crédito Pyme destinado a la adquisición y/o construcción locales, oficinas, bodegas, terrenos industriales o comerciales, así como la construcción de locales propios de la pyme para el incremento de sus activos.</p>
                </div>
            </section>
        </div>
    )
}

export default Services

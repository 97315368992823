import * as React from "react";

import { StaticImage } from 'gatsby-plugin-image';

import '../styles/info.scss';

import {Box} from "@mui/material"

const Info = () => {

    return (
        <Box 
            className="section-info-pymes"
            sx={{
                px: {
                    xs: "0px",
                    sm: "20px"
                }
            }}
        >
            <StaticImage src="../images/info/pymes/1.png" className="img-info-1" alt="Tenemos el impulso que tu empresa necesita, acercate a nosotros" />
            <div className="wrapper-info-1"> 
                <h4>Tenemos el impulso que tu empresa necesita, acercate a nosotros</h4>
               
                <p className="text">En Creditaria, hemos desarrollado un nuevo servicio créditicio a la altura de tu negocio. el Financiamiento para PYMES que requieren cubrir necesidades inmediatas de capital de trabajo, adquirir activo fijo para seguir creciendo. Tener una línea de crédito disponible para usar en cualquier momento y Obtener beneficios fiscales.</p>
            </div>

            <StaticImage src="../images/info/pymes/2.png" className="img-info-2" alt="Somos la mejor opción en Pyme" />
            <div className="wrapper-info-2"> 
                <h4>Somos la mejor opción en Pyme</h4>
               
                <p className="text">Te atenderemos en cualquier parte del país, y con la misma garantía de servicio. El crédito PYME es una alternativa dirigida para las pequeñas y medianas empresas que están enfocadas en hacer crecer su negocio y que necesitan recursos para capital de trabajo o para adquirir activo fijo.</p>
            </div>
        </Box>

    )
}

export default Info
